<template>
  <div class="h5-page">
    <filter-view class="no-flex" @submit="init">
      <div class="c h sb sep-t padding-05" @click="shop.visible = true">
        <div class="no-flex padding-05">自定义区域</div>
        <div class="flex ta-c"></div>
        <div>
          <span>{{ shop.current || "全部" }}</span>
          <i class="el-icon-arrow-right padding-05 fc-g"></i>
        </div>
      </div>
      <div class="c h sb">
        <div class="no-flex padding-10">年度</div>
        <el-input-number v-model="query.year" :min="2000" :max="2099" :step="1" :precision="0" style="width: 2.4rem;" />
      </div>
      <div class="c h sb">
        <div class="no-flex padding-10">起始周</div>
        <el-input-number v-model="query.weekBeg" :min="1" :max="query.weekEnd || 53" :step="1" :precision="0" style="width: 2.4rem;" />
      </div>
      <div class="c h sb">
        <div class="no-flex padding-10">截止周</div>
        <el-input-number v-model="query.weekEnd" :min="query.weekBeg || 1" :max="53" :step="1" :precision="0" style="width: 2.4rem;" />
      </div>
    </filter-view>

    <template v-if="chartStore && chartStore.length">
      <div class="padding-10 h c no-flex fs-small fc-g no-flex">分析结果</div>

      <div class="padding-10-20 h m">
        <el-radio-group v-model="analyType" @change="drawChart">
          <el-radio-button label="quantity">数量</el-radio-button>
          <el-radio-button label="amount">金额</el-radio-button>
        </el-radio-group>
      </div>
      <div ref="chart"></div>
    </template>

    <div class="padding-10 h c no-flex fs-small fc-g no-flex">数据列表</div>
    <el-table v-loading="loading" row-key="id" :data="data" size="small" border>
      <!-- <el-table-column type="index" width="50" /> -->
      <el-table-column prop="year" label="年度" min-width="80" />
      <el-table-column prop="week" label="周" min-width="80" />
      <el-table-column prop="distributorName" label="经销商" min-width="150" />
      <el-table-column prop="areaName" label="区域" min-width="120" />
      <el-table-column prop="balanceQuantity" label="净库存数量" min-width="100">
        <template slot-scope="scope">{{$quantity(scope.row.balanceQuantity)}}</template>
      </el-table-column>
      <el-table-column prop="balanceAmount" label="净库存金额" min-width="100" :formatter="$price" />
    </el-table>
    <el-pagination align="center" :current-page="page + 1" :page-size.sync="size" layout="prev, pager, next" class="no-flex" @current-change="pageChange" :total="total" />

    <shop-list :visible.sync="shop.visible" v-model="query.treeNodeId" :type.sync="query.treeNodeType" @change="handleShopChange" />
  </div>
</template>

<script>
import filterView from "../assembly/filter";
import initData from "@/mixins/initData";
import shopList from "../purchaseAll/shopList";
import request from "@/utils/request";

export default {
  mixins: [initData],
  components: { filterView, shopList },
  data() {
    return {
      query: {
        year: null,
        weekBeg: null,
        weekEnd: null,
        treeNodeId: null,
        treeNodeType: null,
      },
      shop: {
        visible: false,
        current: null,
      },
      analyType: "quantity",
      chart: null,
      chartStore: [],
    };
  },
  methods: {
    beforeInit() {
      this.url = "@host:analysis;api/distributorStockReport";
      this.params = Object.assign(
        {
          page: this.page,
          size: this.size,
          sort: "week,desc",
        },
        this.query
      );
      if (this.page === 0) {
        this.loadChartData();
      }
      return true;
    },
    loadChartData() {
      request({
        url: "@host:analysis;api/distributorStockReport/byWeek",
        method: "get",
        params: this.query,
      }).then((res) => {
        res.forEach((d) => {
          d.quantity = Math.round(d.quantity);
          d.amount = d.amount / 100;
        });
        this.chartStore = res;
        this.$nextTick(() => {
          this.drawChart();
        });
      });
    },
    handleShopChange(data) {
      this.shop.current = data ? data.name : "全部";
      this.page = 0;
      this.init();
    },
    drawChart() {
      if (this.chartStore && this.chartStore.length > 0) {
        if (!this.chart) {
          this.chart = echarts.init(this.$refs.chart, "light", {
            height: 420,
          });
          this.chart.on("click", (e) => {
            let week = (e.name || e.value).replace("第", "").replace("周", "");
            if (week) {
              this.filterWeek = week;
              this.toQuery();
            }
          });
        }

        this.chart.clear();
        this.chart.setOption({
          tooltip: {
            trigger: "axis",
            axisPointer: {
              type: "shadow",
            },
          },
          grid:{
            top: 10,
            right: 10,
            bottom: 10,
            left: 10,
            containLabel: true
          },
          xAxis: {
            type: "category",
            data: this.chartStore.map((o) => {
              return `W${o.week}`;
            }),
            triggerEvent: true,
          },
          yAxis: {
            type: "value",
          },
          series: {
            type: "bar",
            barMaxWidth: 50,
            barGap: "0%",
            data: this.chartStore.map((o) => {
              return this.analyType === "quantity"
                ? o.balanceQuantity
                : o.balanceAmount / 100;
            }),
          },
        });
      } else {
        this.chart = null;
      }
    },
  },
  mounted() {
    const now = new Date();
    this.query.year = now.getFullYear();
    this.query.weekEnd = now.getWeek();
    let bw = this.query.weekEnd - 7;
    if (bw < 1) bw = 1;
    this.query.weekBeg = bw;
    this.init();
  },
};
</script>